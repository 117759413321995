<template>
  <div class="container" v-if="content">
    <header class="jumbotron">
      <h3 >Integrated Offer</h3>
      <h2>{{ content.data.name }}</h2>
    </header>
    <div>
      <div>
        <p>Total cost of ingredients: ${{ content.data.ingredientsTotalCost }}</p>
        <p>Total cost of products: ${{ content.data.productsTotalCost }}</p>
        <p>Total cost of products: ${{ content.data.totalCost }}</p>
        <p>price ratio: {{ content.data.priceRatio }}</p>
        <p>calculated sale price: ${{ content.data.calculatedPrice }}</p>
        <p>fixed sale price (menu price): ${{ content.data.fixedPrice }} <router-link :to="{ name: 'integrated-offer-price', params: { 'integratedOfferId': content.data.id }}">update price</router-link></p>
        <p>precentage of gains over the fixed price: {{ content.data.precentageOfGains }}%</p>
      </div>

      <h3>Ingredients</h3>
      <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Unit Name</th>
              <th scope="col">Unit Amount</th>
              <th scope="col">Unit Fixed cost</th>
              <th scope="col">Amount</th>
              <th scope="col">Calculated Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, key) in content.data.ingredients" v-bind:key="key">
              <th scope="row">{{value.id}}</th>
              <th scope="row">{{value.name}}</th>
              <th scope="row">{{value.unit_name}}</th>
              <th scope="row">{{value.unit_amount}}</th>
              <th scope="row">${{value.unit_fixed_cost}}</th>
              <th scope="row">{{value.amount}}</th>
              <th scope="row">${{value.calculated_cost}}</th>
            </tr>
          </tbody>
        </table>

        <h3>products</h3>
      <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Unit Name</th>
              <th scope="col">Unit Amount</th>
              <th scope="col">Unit Fixed cost</th>
              <th scope="col">Amount</th>
              <th scope="col">Calculated Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, key) in content.data.products" v-bind:key="key">
              <th scope="row">{{value.id}}</th>
              <th scope="row">{{value.name}}</th>
              <th scope="row">{{value.unit_name}}</th>
              <th scope="row">{{value.unit_amount}}</th>
              <th scope="row">${{value.unit_fixed_cost}}</th>
              <th scope="row">{{value.amount}}</th>
              <th scope="row">${{value.calculated_cost}}</th>
            </tr>
          </tbody>
        </table>

    </div>
  </div>
</template>

<script>
import IntegratedOfferService from "../services/integrated-offer.service"
import { useRoute } from 'vue-router'
export default {
  name: "ingredient-offer-view",
  props: {
  },
  setup() {
    // const route = useRoute()

    // onMounted(() => {
    //   const productId = route.params.productId
    //   console.log(productId);
    // })
  },
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    if (this.loggedIn) {
      // this.$router.push("/profile");
    }
    const route = useRoute()
    IntegratedOfferService.getIntegratedOffer(route.params.integratedOfferId).then(
      (response) => {
        this.content = response.data;
        console.log(this.content);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};

</script>